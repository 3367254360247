import Flickity from "flickity";
import Img from "gatsby-image";
import React from "react";

import styles from "./ProjectGallery.module.css";

class ProjectGallery extends React.Component {
  constructor(props) {
    super(props);

    this.carousel = null;
  }

  setupCarousel() {
    this.carousel = new Flickity(".carousel", {
      adaptiveHeight: false,
      arrowShape: {
        x0: 20,
        x1: 65,
        y1: 45,
        x2: 70,
        y2: 40,
        x3: 30,
      },
      cellSelector: ".carousel-cell",
      pageDots: false,
      setGallerySize: false,
      wrapAround: true,
    });

    this.carousel.on(
      "staticClick",
      (event, pointer, cellElement, cellIndex) => {
        this.carousel.next();
      }
    );
  }

  componentDidMount() {
    this.setupCarousel();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.fullscreen !== nextProps.fullscreen) {
      if (nextProps.index) {
        this.carousel.select(nextProps.index);
      }
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div
        className={`carousel ${styles.el} ${this.props.fullscreen &&
          styles.fullscreen}`}
      >
        <button className={styles.close} onClick={() => this.props.closeGallery()}>
          <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg"><path class="stroke" d="M0 0l18 18M18 0L0 18"></path></svg>
        </button>
        {this.props.project.galleryImages.map((image, key) => (
          <div className={`${styles.cell} carousel-cell`} key={key}>
            <Img
              className={styles.cellImage}
              fluid={image.fluid}
              loading="eager"
              objectFit="contain"
              objectPosition="50% 50%"
            />
          </div>
        ))}
      </div>
    );
  }
}

export default ProjectGallery;
