import React from "react";

import styles from "./ProjectShare.module.css";

class ProjectShare extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      menu: false,
    };
  }

  openMenu = () => {
    this.setState(
      {
        menu: true,
      },
      () => document.body.classList.add("lockedMobile")
    );
  };

  closeMenu = () => {
    this.setState(
      {
        menu: false,
      },
      () => document.body.classList.remove("lockedMobile")
    );
  };

  openWindow = href => {
    window.open(
      this.href,
      "targetWindow",
      "toolbar=no,location=0,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=250"
    );

    return false;
  };

  render() {
    return (
      <React.Fragment>
        <button
          type="button"
          aria-label="Open menu"
          className={styles.openButton}
          onClick={() => this.openMenu()}
        >
          Share
        </button>
        <div className={`${styles.menu} ${this.state.menu && styles.active}`}>
          <button
            type="button"
            aria-label="Close menu"
            className={styles.menuScrim}
            onClick={() => this.closeMenu()}
          />
          <div className={styles.menuPanel}>
            <a
              className={styles.link}
              href={`mailto:?subject=${this.props.title} via Glen Fries Associates Architects&body=https://www.glenfries.com/portfolio/${this.props.slug}/`}
            >
              Email
            </a>
            <a
              className={styles.link}
              href={`https://www.facebook.com/sharer.php?u=https://www.glenfries.com/portfolio/${this.props.slug}/`}
              onClick={() => this.openWindow(this.href)}
            >
              Facebook
            </a>
            <a
              className={styles.link}
              href={`https://twitter.com/share?url=https://www.glenfries.com/portfolio/${this.props.slug}/&text=${this.props.title}`}
              onClick={() => this.openWindow(this.href)}
            >
              Twitter
            </a>
            {/*<a className={styles.link} href={`https://www.linkedin.com/sharing/share-offsite/?url=https://www.glenfries.com/portfolio/${this.props.slug}/`}>
              LinkedIn
            </a>*/}
            <a
              className={styles.link}
              href={`http://pinterest.com/pin/create/button/?url=https://www.glenfries.com/portfolio/${this.props.slug}/&media=${this.props.image}&description=${this.props.title}`}
            >
              Pinterest
            </a>
            <button
              className={styles.closeButton}
              onClick={() => this.closeMenu()}
            >
              Close
            </button>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProjectShare;
