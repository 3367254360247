import React from "react";
import Divider from "../Divider";
import PortfolioNav from "../Portfolio/PortfolioNav";
import styles from "./index.module.css";
import ProjectGallery from "./ProjectGallery";
import ProjectGrid from "./ProjectGrid";
import ProjectInfo from "./ProjectInfo";

class Project extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      galleryFullscreen: false,
      galleryIndex: null,
    };
  }

  closeGallery = () => {
    this.setState({
      galleryFullscreen: false,
    });
  };

  openGallery = index => {
    if (window.innerWidth > 639) {
      this.setState({
        galleryFullscreen: true,
        galleryIndex: index,
      });
    }
  };

  render() {
    return (
      <div className={styles.el}>
        <div className={styles.container}>
          <Divider />
          <PortfolioNav sections={this.props.sections} />
          <Divider />
          <ProjectGallery
            closeGallery={this.closeGallery}
            fullscreen={this.state.galleryFullscreen}
            index={this.state.galleryIndex}
            project={this.props.project}
          />
          <ProjectInfo project={this.props.project} />
          <Divider />
          <ProjectGrid
            openGallery={this.openGallery}
            project={this.props.project}
          />
        </div>
      </div>
    );
  }
}

export default Project;
