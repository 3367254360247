import Img from "gatsby-image";
import React from "react";

import styles from "./ProjectGrid.module.css";

const ProjectGrid = props => (
  <div className={styles.el}>
    {props.project.galleryImages.map((image, i) => (
      <button
        className={`${styles.cell} ${image.fluid.aspectRatio < 1 &&
          styles.vertical}`}
        key={i}
        onClick={() => props.openGallery(i)}
      >
        <Img
          aspectRatio={image.fluid.aspectRatio}
          className={styles.image}
          fluid={image.fluid}
          objectFit="contain"
          objectPosition="50% 50%"
        />
      </button>
    ))}
  </div>
);

export default ProjectGrid;
