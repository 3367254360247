import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import Project from "../components/Project";
import SEO from "../components/Seo";

const ProjectTemplate = ({ pageContext }) => (
  <Layout>
    <SEO
      title={pageContext.dataNode.metaTitle}
      description={pageContext.dataNode.metaDescription}
    />
    {pageContext.dataNode.slug === "commercial" && (
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHTML: `{
              "@context": "http://schema.org",
              "@type": "Service",
              "serviceType": "Commercial Architect",
              "category": "Architect",
              "provider": {
                "@type": "Organization",
                "name": "Glen Fries Associates Architects"
              },
              "areaServed": {
                "@type": "Country", // Can also use type "State" here if service isn't national
                "name": "USA"
              }
            }`,
          },
        ]}
      />
    )}
    <Project project={pageContext.dataNode} sections={pageContext.sections} />
  </Layout>
);

export default ProjectTemplate;
