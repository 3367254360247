import React from "react";
import styles from "./ProjectInfo.module.css";
import ProjectShare from "./ProjectShare";

const ProjectInfo = props => (
  <div className={styles.el}>
    <h1 className={styles.title}>{props.project.title}</h1>
    {props.project.description && (
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{
          __html: props.project.description.childMarkdownRemark.html,
        }}
      />
    )}
    <ProjectShare
      image={props.project.thumbnailImage.fluid.src}
      slug={props.project.slug}
      title={props.project.title}
    />
  </div>
);

export default ProjectInfo;
